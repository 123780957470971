<template>
    <div class="ARVisorMarker" id="ARVisorMarker">
        <video class="video-background" autoplay muted playsinline></video>

        <div class="title-wrap title-ok">
            <div class="title-container">{{ $t("ARVisorMarker.title") }}</div>
            <div class='ok ok-disable' v-on:click="closeAR()">{{ $t("ARVisorMarker.close")}}</div>
        </div>

        <div id="draggable" class="ui-widget-content draggable">
            <img :src="require('@/markers/casa2.png')" alt="">
        </div>

        <figure class="mano"><img :src="require('@/assets/manita.png')" alt=""></figure>

        <!--<button class="round-btn close" v-on:click="closeAR()">
            <img :src="require('@/assets/02_generales/cerrar.png')" alt="close button" />
        </button>-->

        <AppAlert ref="appAlert" />
    </div>
</template>
  
<script>
import { Analytic } from "./../../public/js/analytics/Analytic.js";
import AppAlert from "@/components/AppAlert.vue";
import { Howl, Howler } from 'howler';

const $ = require('jquery');
var Draggabilly = require('draggabilly');


var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "ARVisorMarker",
});

export default {
    name: "ARVisorMarker",
    data() {
        return {
            ok: false,
            timeout: false,
            title2: this.$t("ARVisorMarker.title2"),
            angle: 0,
            isIOS: false,
            indicator: null,
            pointDegree: null,
            compass: null,
            location: null,
            isUserNearEnough: false,
            currentRoute: null,
            outsideCoordinates: [],
            videoConstraints: {
                video: {
                    facingMode: {
                        exact: "environment",
                    },
                },
            },
        };
    },
    mounted() {
        this.currentRoute = this.$store.state.parkData.canalArea;
        this.$store.commit("arShow", true);
        analytic.registerEvent("AR Visor Marker", "open");
        if (this.needsOnboarding()) {
            this.$store.commit("setAROnboardingAsShown");
            this.$refs.appAlert.showAlert({
                type: "message",
                msg: this.$t("ARVisorMarker.onBoardingMessage"),
            });
        }
        this.sound = new Howl({
            src: ['audios/baroque_ra7.mp3'],
            html5: true,
            loop: true,
            volume: 0.5
        })
        this.sound.play();

        this.loadCameraBackground();
        this.isIOS = this.checkForiOS();

        this.initDrag();
    },
    components: {
        AppAlert,
    },
    methods: {
        initDrag() {
            var title2 = this.title2;
            // if you have multiple .draggable elements
            // get all draggie elements
            var draggableElems = document.querySelectorAll('.draggable');
            // array of Draggabillies
            var draggies = []
            // init Draggabillies
            for (var i = 0; i < draggableElems.length; i++) {
                var draggableElem = draggableElems[i];
                var draggie = new Draggabilly(draggableElem, {
                    // options...
                });
                draggie.on('dragStart', function () {
                    $(".mano").fadeOut();
                    setTimeout(() => {
                        $(".title-container").html(title2);
                        $(".title-wrap").removeClass("title-ok");

                        setTimeout(() => {
                            this.ok = true;
                            console.log(this.ok);
                            $(".ok").removeClass('ok-disable');
                        }, 1050);
                    }, 2000);
                });
                draggies.push(draggie);
            }
        },
        checkForiOS() {
            return (
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            );
        },
        loadCameraBackground() {
            if (
                !("mediaDevices" in navigator) ||
                !("getUserMedia" in navigator.mediaDevices)
            ) {
                return;
            }

            const video = document.querySelector(".video-background");
            if ("mediaDevices" in navigator && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia({ video: { facingMode: { exact: "environment" } } })
                    .then((stream) => {
                        video.srcObject = stream;
                    })
                    .catch((error) =>
                        console.log("Ocurrió un error, inténtalo de nuevo ", error)
                    );
            }
        },
        closeAR: function () {
            analytic.registerEvent("AR Visor Marker", "closed");
            if (this.sound != null) {
                this.sound.stop();
                this.sound.unload();
                this.sound = null;
            }
            this.$router.push("canal");
        },
        needsOnboarding() {
            let onBoardingAlreadyShown = this.$store.state.arOnboardingShown;
            return !onBoardingAlreadyShown;
        },
    },
    beforeDestroy() {
        //this.video = document.getElementById('arjs-video');
        //document.body.removeChild(this.video);
        //location.reload();
        if (this.sound != null) {
            this.sound.stop();
            this.sound.unload();
            this.sound = null;
        }
    }
};
</script>
  
<style lang="scss">
.ARVisorMarker {
    position: fixed;
    background-color: black;
    background: repeating-linear-gradient(45deg,
            #606dbc,
            #606dbc 10px,
            #465298 10px,
            #465298 20px);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;

    #draggable {
        width: 14rem;
        height: 14rem;
        padding: 0.5em;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .mano {
        width: 5rem;
        height: 5rem;
        padding: 0.5em;
        pointer-events: none;
        position: absolute;
        animation: opacidad 2s linear infinite;
        -o-animation: opacidad 2s linear infinite;
        -webkit-animation: opacidad 2s linear infinite;
        -moz-animation: opacidad 2s linear infinite;
        margin: auto;
        left: 13rem;
        right: 0;
        top: 9rem;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .video-background {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .close {
        left: unset;
        right: 1rem;
    }

    a-scene {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .title-ok{
        bottom: -0.5rem;
    }

    .title-wrap{
        display: flex;
        flex-flow: column;
        position: absolute;
        bottom: 5%;
        width: 100%;

        .title-container {
            position: relative;
            left: 0;
            right: 0;
            //bottom: 7%;
            margin: auto;
            color: #F8F7F3;
            font-size: 1.5rem;
            font-family: "lexend";
            width: 86%;
            display: flex;
            flex-flow: column;
        }

        .ok-disable{
            pointer-events: none;
            opacity: 0;
        }

        .ok {
            margin-top: 7%;
            position: relative;
            width: 8rem;
            background: var(--app-buttonG);
            color: var(--app-black);
            font-family: "lexend";
            border-radius: 0.8rem;
            text-align: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            transition: all 0.2s ease;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            padding: 0.2rem;
            color: white;
            align-self: center;
        }
    }

    .title-ok{
        bottom: -1rem;
    }

    .disclaimer-container {
        position: absolute;
        color: white;
        background-color: var(--app-modalBg);
        border-radius: 0.5rem;
        width: 95%;
        flex: auto;
        bottom: 1vh;
        left: 0;
        right: 0;
        text-align: left;
        margin: auto;
        padding: 0.5rem 1rem;
        font-family: "lexend";
        font-size: 0.8rem;
        z-index: 1;
    }

    .onBoarding {
        z-index: 12;
    }
}

@keyframes opacidad {
    0%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
    50%{
        opacity: 0.5;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    100%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
}

@-webkit-keyframes opacidad {
    0%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
    50%{
        opacity: 0.5;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    100%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
}
</style>