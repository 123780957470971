<template>
  <div class="gallery">
    <v-zoomer
      ref="zoomer"
      :minScale="zoomer.minScale"
      :maxScale="zoomer.maxScale"
      :pivot="zoomer.pivot"
      :zoomingElastic="zoomer.zoomingElastic"
      :limitTranslation="zoomer.limitTranslation"
    >
      <img class="image-container" :src="images[index]" />
    </v-zoomer>

    <button v-if="currentIndex > 0" class="arrow prev" @click="loadPrevImage">
      <img :src="require('@/assets/prev.png')" />
    </button>

    <button
      v-if="currentIndex < images.length - 1"
      class="arrow next"
      @click="loadNextImage"
    >
      <img :src="require('@/assets/next.png')" />
    </button>

    <button class="close round-btn" @click="closeModal">
      <img
        :src="require('@/assets/02_generales/cerrar.png')"
        alt="close button"
      />
    </button>
  </div>
</template>

<script>
import VueZoomer from "vue-zoomer";
const $ = require('jquery');

export default {
  name: "gallery",
  props: ["images", "index"],
  data() {
    return {
      currentIndex: 0,
      currenImage: null,
      zoomer: {
        minScale: 1,
        maxScale: 3,
        pivot: "image-center",
        zoomingElastic: false,
        limitTranslation: true,
      },
    };
  },
  components: {
    VZoomer: VueZoomer.Zoomer,
  },
  mounted() {
    this.currentIndex = this.index;
    this.currenImage = this.images[this.currentIndex];
    $(".next, .prev").addClass("pulse");
  },
  methods: {
    loadPrevImage() {
      if (this.currentIndex - 1 < 0) {
        return;
      }
      $(".next, .prev").removeClass("pulse");
      this.currentIndex = parseInt(this.currentIndex) - 1;
      document.querySelector(".image-container").src =
        this.images[this.currentIndex];
        setTimeout(() => {
          $(".next, .prev").addClass("pulse");
        }, 100);
    },
    loadNextImage() {
      /*if (this.currentIndex + 1 > this.images.length - 1) {
        console.log("index", this.currentIndex + 1);
        console.log("length", this.images.length - 1);
        return;
      }*/
      $(".next, .prev").removeClass("pulse");
      this.currentIndex = parseInt(this.currentIndex) + 1;
      document.querySelector(".image-container").src =
        this.images[this.currentIndex];
        setTimeout(() => {
          $(".next, .prev").addClass("pulse");
        }, 100);
    },
    closeModal() {
      this.$parent.closeGallery();
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  position: fixed;
  //background-color: rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .vue-zoomer {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .arrow {
    position: absolute;
    border: unset;
    border-radius: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative;
      width: 90%;
      height: auto;
    }
    &.prev {
      left: 1rem;
    }
    &.next {
      right: 1rem;
    }
  }

  .pulse{
    animation: pulse 2s linear infinite;
  }

  .close {
    right: 1rem;
    left: unset;
  }
}

@keyframes pulse{
  0%{
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50%{
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
  }
  100%{
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
