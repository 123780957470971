<template>
  <div class="last-spot">
    <div class="wrapper">
      <figure class="star-container">
        <img :src="starImage" alt="final star" />
      </figure>
      <div class="helper">
        <span class="last-spot-title">
          {{ $t("last-spot-title") }}
        </span>
        <span
          class="last-spot-instruction"
          v-html="$t('last-spot-instruction')"
        >
        </span>
      </div>
    </div>
    <big-button :onClick="goToRewards">{{
      $t("last-spot-goToRewards")
    }}</big-button>
  </div>
</template>

<script>
import BigButton from "@/components/BigButton.vue";
export default {
  name: "LastSpot",
  components: {
    BigButton,
  },
  mounted() {
    this.$i18n.locale = this.$store.state.locale;
  },
  data() {
    return {};
  },
  methods: {
    goToRewards() {
      this.$router.push("Rewards");
    },
  },
  computed: {
    starImage() {
      return require("@/assets/final_star.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.last-spot {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0rem 1.7rem;
    overflow: auto;
    padding-top: 4.5rem;
    text-align: initial;

    .star-container {
      position: relative;
      max-width: 8rem;
      width: 40%;

      img {
        position: relative;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .helper {
      overflow: auto;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 2rem 0;

      span {
        font-family: "lexend";
        display: block;
        font-size: 1.2rem;

        &.last-spot-title {
          font-family: "lexend";
          color: #336FB7;
          font-size: 1.6rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .big-button {
    margin: 0.5rem 2.5rem;
    width: unset;
    font-size: 1.2rem;
  }
}
</style>
