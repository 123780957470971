<template>
  <div
    class="big-button"
    :class="[
      {
        answer: type === 'answer',
      },
      {
        noCaps: !allCaps,
      },
    ]"
    :data-selectable="selectable"
    @click="onClick"
  >
    <slot v-if="withImage == null || withImage == undefined"></slot>
    <img v-if="withImage != null" :src="getImage" alt="button image" />
  </div>
</template>

<script>
export default {
  name: "BigButton",
  props: {
    type: {
      type: String,
      required: false,
    },
    withImage: {
      type: String,
    },
    selectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
    allCaps: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  mounted() {},
  computed: {
    getImage() {
      return require(`@/assets/button/${this.withImage}`);
    },
  },
};
</script>

<style lang="scss">
.big-button {
  position: relative;
  margin: 0.5rem 0rem;
  padding: 1rem;
  width: 80%;
  font-size: 1.6rem;
  background: var(--app-buttonG);
  color: #F8F7F3;
  font-family: "lexend";
  border-radius: 0.8rem;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  transition: all 0.2s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &.noCaps {
    text-transform: none;
  }

  img {
    position: relative;
    width: auto;
    height: 65%;
  }

  &.answer {
    font-size: 1.4rem;
    padding: 0.8rem;
    background: linear-gradient(180deg, #EDBD2E 0%, #B68D40 9.38%);
    color: #F8F7F3;
    margin: 0.5rem;

    &.selected {
      background: linear-gradient(180deg, #5E91CC 0%, #336FB7 13.54%);
      color: #F8F7F3;
      transform: translateY(0.2rem);
      box-shadow: inset 0px 10px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &:active {
    transform: translateY(0.2rem);
    background: linear-gradient(180deg, #5E91CC 0%, #336FB7 13.54%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //filter: saturate(2);
  }
}
</style>
