<template>
    <div class="selectRoute">
        <div class="selected-wrap" >
            <div class="instruction"><b>Selecciona una de las dos rutas para continuar.</b></div>
            <div class="route-wrap">
                <div v-on:click="selectRouteOption(1)" class="route-btn">Ruta 1</div>
                <div v-on:click="selectRouteOption(2)" class="route-btn">Ruta 2</div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Park from "@/models/Park.js";
import Route from "@/models/Route.js";
import NextRoute from "../js/NextRoute.js";

export default {
    name: "SelectRoute",
    watch: {
        /*"$i18n.locale": function () {
            this.localizedData();
        },*/
        "$store.state.locale": function () {
            this.park = new Park(this.$store.state.parkData);
            this.route = new Route(this.park.getRouteWithId(this.routeId));
            this.$store.commit("currentRoute", this.route);
            let nextRouteHelper = new NextRoute(this.route);
            nextRouteHelper.getNextRouteData();
        },
    },
    mounted() {
        //this.localizedData();
        //this.park = new Park(this.$store.state.parkData);
        var complete0 = this.$store.state.finishedRoutes;
        var checkSelectRoute = this.$store.state.typeRoute;
        if (complete0.includes("entrada") && complete0.includes("canal") && checkSelectRoute == null) {
            this.selectRoute = true;
        }
    },
    data() {
        return {
            park: null,
            routeId: null,
            routeShown: false,
            selectRoute: false,
            checkSelectRoute: null,
            name: null,
            route: null,
            information: null,
            gallery: null,
            challenge: null,
            currentIndex: null,
            isThisChallengeFinished: false,
            onlyDetail: false,
            galleryIsShown: false,
            selectedImage: null,
            selectedImageIndex: null,
        };
    },
    methods: {
        selectRouteOption: function(option){
            this.$store.commit("typeRoute", option);
            this.park = new Park(this.$store.state.parkData);
            var parkData0 = this.$store.state.parkData;
            //this.route = new Route(this.park.getRouteWithId(this.routeId));
            //console.log(this.route);
            //this.$store.commit("currentRoute", this.route);
            //let nextRouteHelper = new NextRoute(this.route);
            //nextRouteHelper.getNextRouteData();
            //this.$router.push("NextRoute");
            parkData0.pointsGeo = this.getSpots();
            this.$store.commit("parkData", parkData0);
        },
        getSpots(){
            var spots = [];
            var typeR = this.$store.state.typeRoute;
            var routesGeo = this.$store.state.parkData.routesGeo
            routesGeo.map((route) => {
                if (spots.filter((spot) => spot.properties.id == route.properties.startId).length == 0 && (route.properties.typeRoute == typeR || route.properties.typeRoute == 0)) {
                    spots.push({
                    properties: {
                        id: route.properties.startId,
                        name: route.properties.start,
                        type: route.properties.startPopupType,
                        order: route.properties.startOrder,
                        typeRoute: route.properties.typeRoute,
                    },
                    geometry: { coordinates: route.geometry.coordinates[0] },
                    });
                }

                if (spots.filter((spot) => spot.id == route.endId).length == 0 && (route.properties.typeRoute == typeR || route.properties.typeRoute == 0)) {
                    spots.push({
                    properties: {
                        id: route.properties.endId,
                        name: route.properties.end,
                        type: route.properties.endPopupType,
                        order: route.properties.endOrder,
                        typeRoute: route.properties.typeRoute,
                    },
                    geometry: {
                        coordinates:
                        route.geometry.coordinates[route.coordinates.length - 1],
                    },
                    });
                }
            });

            return spots;
        }
        /*localizedData() {
            this.routeId = this.$store.state.currentRoute.id;
            let park = new Park(this.$store.state.parkData);
            this.$store.commit("setParkData", park);
            let route = park.getRouteWithId(this.routeId);
            this.$store.commit("currentRoute", route);
        },*/
    }
};
</script>

<style lang="scss" scope>
    .selectRoute {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    .instruction {
        margin: 1rem 0;
        font-size: 1rem;
    }

    .route-wrap {
        display: flex;
        justify-content: space-evenly;

        .route-btn {
            position: relative;
            margin: 0.5rem 0rem;
            padding: 1rem;
            width: 45%;
            font-size: 1.4rem;
            background-color: var(--app-buttonG);
            color: var(--app-black);
            font-family: "Arial-black", monospace, sans-serif;
            border-radius: 0.8rem;
            text-transform: uppercase;
            text-align: center;
            box-shadow: 0px 3px 4px 0px darkgrey;
            transition: all 0.2s ease;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>