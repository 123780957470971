<template>
  <div class="complete">
    <div class="helper">
      <div class="congrats-container">
        <span>{{ $t("complete-congrats") }}</span>
        <span class="second">{{ $t("complete-congrats2") }}</span>
      </div>
      <span class="final-prize-instruction">
        {{ $t("complete-gift") }}
      </span>
      <figure class="gold-prize">
        <img :src="goldPrizeImg" alt="gold prize image" />
      </figure>
      <span class="winnerCode">{{ idWinner }}</span>
    </div>
    <figure class="confetti" v-if="gotAllPrizes">
      <img src="img/estrellas.gif" alt="">
    </figure>
    <big-button v-if="showRating" :onClick="advance">{{
        $t("complete-goToRate")
    }}</big-button>
    <big-button class="plus" :onClick="discover">{{
        $t("complete-keep-discovering")
    }}</big-button>

    <transition name="fade" mode="out-in">
      <crossPena v-if="crossShown"></crossPena>
    </transition>
  </div>
</template>

<script>
import BigButton from "@/components/BigButton.vue";
import crossPena from "@/components/CrossPena.vue";
import { Analytic } from "../../public/js/analytics/Analytic.js";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "RouteComplete",
};
var analytic = new Analytic(options);
export default {
  name: "Complete",
  components: {
    BigButton,
    crossPena,
  },
  mounted() {
    analytic.registerEvent("Challenge Completed", "open");
    this.$i18n.locale = this.$store.state.locale;
    this.gotAllPrizes = this.$store.getters.allChallengesDone;
    if (this.gotAllPrizes) {
      this.idWinner = analytic.create_winner_code()[1];
      this.originCode = analytic.create_winner_code()[0];
      this.$store.commit("winnerCode", this.idWinner);
      analytic.registerEvent("Winner Code", this.idWinner);
      analytic.registerEvent("Original Code", this.originCode);
    }
    setTimeout(() => {
      var conf = document.querySelector(".confetti");
      conf.style.display = "block";
    }, 300);
    setTimeout(() => {
      if (this.$store.state.crossShow == false) {
        this.crossShown = true
      }
    }, 5000);
    setTimeout(() => {
      var el = document.querySelector(".confetti");
      el.style.display = "none";
    }, 6200);
  },
  deactivated() {
    analytic.registerEvent("Challenge Completed", "closed");
  },
  data() {
    return {
      gotAllPrizes: false,
      idWinner: "",
      originCode: "",
      crossShown: false,
    };
  },
  methods: {
    dismissCross() {
      this.crossShown = false;
      this.$store.commit("crossShow", true);
    },
    advance() {
      this.$router.push("Rating");
    },
    discover() {
      this.$router.push("Discover");
    },
    checkIfGotAllPrizes() {
      // let challenges = this.$store.state.finishedChallenges;
      // let corrects = challenges.filter((ch) => ch.correct);
      // this.gotAllPrizes = corrects.length === challenges.length;
      this.gotAllPrizes = this.$store.getters.allChallengesDone;
    },
  },
  computed: {
    goldPrizeImg() {
      return require(`@/assets/06_camafeos/medallaestrella1.png`);
    },
    goldStarImg() {
      return require(`@/assets/final_star.png`);
    },
    showRating() {
      return this.$store.state.showRating;
    },
  },
};
</script>

<style lang="scss" scoped>
.complete {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--app-bg);

  .confetti {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    pointer-events: none;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .helper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    margin: 7.5rem 1rem 0rem 1rem;
    //padding: 0.7rem;
    overflow: auto;
    mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%);

    .congrats-container {
      margin-top: 1rem;
      //padding: 0 1rem;
      font-family: "lexend";
      font-size: 1.2rem;
      text-align: center;

      span {
        font-size: 1.8rem;
        display: block;

        &:nth-child(1) {
          font-size: 1.6rem;
          text-align: center;
          margin: 1rem 0 0 0;
          //align-self: flex-start;
          color: #92682B;
        }

        &:nth-child(2) {
          margin-top: 1rem;
          font-size: 1.4rem;
          color: #336FB7;
        }
      }

      .second {
        font-size: 1.4rem !important;
        color: #336FB7 !important;
        margin: 1rem 0 1rem 0 !important;
      }
    }

    .gold-prize {
      position: relative;
      width: 100%;
      margin: 1rem 0rem 0rem 0rem;

      img {
        position: relative;
        width: 40%;
        object-fit: contain;
      }
    }

    .winnerCode {
      position: relative;
      text-align: center;
      display: block;
      font-family: "montserrat";
      text-transform: uppercase;
      font-size: 1rem;
      margin-bottom: 1rem;
      color: #786721;
    }

    .final-prize-instruction {
      position: relative;
      text-align: center;
      display: block;
      margin-top: 1rem;
      font-family: "montserrat";
      line-height: 1.7rem;
      font-size: 1.1rem;
    }

    .complete-info {
      position: relative;
      flex: 0;
      text-align: initial;
      margin: 3rem 0rem 1rem 0rem;
      font-size: 1.1rem;

      span {
        display: block;

        &:nth-child(2) {
          margin-top: 1rem;
        }
      }
    }
  }

  .big-button {
    margin: 0.5rem 2.5rem;
    width: unset;
    font-size: 1.2rem;

    &.plus {
      background: linear-gradient(180deg, #EDBD2E 0%, #B68D40 9.38%);
      // padding-right: 2.4rem;
      // background-repeat: no-repeat;
      // background-position: 95% center;
      // background-size: 1.5rem;
      // background-image: url("~@/assets/plus.png");
    }
  }
}

@media (max-height: 700px) {
  .helper {
    justify-content: flex-start;
  }
}

@media (min-height: 701px) {
  .helper {
    justify-content: center;
  }
}
</style>
