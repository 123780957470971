<template>
    <div class="info">
      <button class="round-btn round-btn-help" @click="closeModal">
        <img :src="require('@/assets/02_generales/cerrar.png')" alt="close button" />
      </button>
  
      <div v-if="section" class="wrapper">
        <p class="title">
          {{ $t("s-" + section) }}
        </p>
        <div class="content-wrapper" v-html="sectionDescription"></div>
      </div>
    </div>
  </template>
  
  <script>
  const $ = require('jquery');
  window.$ = $;
  import { Analytic } from "../../public/js/analytics/Analytic.js";
  var options = {
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Info",
  };
  var analytic = new Analytic(options);
  export default {
    name: "Info",
    async mounted() {
      await this.$nextTick();
      var total = this.$store.state.numRoutes;
      $(".total").html(total);
    },
    deactivated() {
      analytic.registerEvent(`Info: ${this.section}`, "closed");
    },
    computed: {
      sectionDescription() {
        analytic.registerEvent(`Info: ${this.section}`, "open");
        return this.$store.state.parkData[this.section].text;
      },
    },
    data() {
      return {
        parkData: null,
        section: "help",
      };
    },
    methods: {
        closeModal() {
            this.$router.go(-1);
        }
    },
  };
  </script>
  
  <style lang="scss">
  .info {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--app-bg);
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;

    .round-btn-help {
        left: unset;
        right: 1rem;
    }
  
    .wrapper {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column;
      align-items: center;
      margin: 0rem 1.7rem;
      overflow: hidden;
      max-height: 90%;
  
      .title {
        font-family: "lexend";
        font-size: 1.8rem;
        text-align: left;
        margin: 1rem 0 0 0;
        color: #336FB7;
        align-self: flex-start;
  
        &.special {
          font-family: "lexend";
  
          &.centro {
            text-align: center;
            width: 100%;
            line-height: 2rem;
          }
        }
      }
  
      .content-wrapper {
        position: relative;
        flex: 1;
        margin: 1rem 0;
        width: 100%;
        overflow: auto;
        text-align: initial;
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding-right: 1.5rem;
        mask-image: linear-gradient(0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 2%,
            rgba(0, 0, 0, 1) 98%,
            rgba(0, 0, 0, 0) 100%);
        -webkit-mask-image: linear-gradient(0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 2%,
            rgba(0, 0, 0, 1) 98%,
            rgba(0, 0, 0, 0) 100%);
        padding: 1rem 0rem;
        align-items: center;
        display: flex;
        flex-flow: column;
        padding-right: 0.6rem;

        .banner{
          width: 100%;
          margin: 0 auto;

          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
  
        .store {
          width: 100%;
          display: flex;
          flex-flow: row-reverse;
          justify-content: space-evenly;
          align-items: center;
  
          .playstore,
          .appstore {
            width: 45%;
  
            .img1 {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
  
        .infDiscover {
          width: 100%;
        }
  
        p {
          text-align: center;
          //font-size: 1.25rem;
          line-height: 2rem;
          margin-bottom: 3rem;
  
          &:last-of-type {
            padding-bottom: 1rem;
          }
  
          b {
            color: #336FB7;
          }
  
          a {
            color: #336FB7 !important;
            border-bottom: 2px solid;
  
            b {
              color: #336FB7;
            }
          }
  
          .total {
            width: auto !important;
          }
  
          span {
            display: inline-block;
            width: 45%;
            text-align: right;
  
            &.center1 {
              text-align: center;
              font-family: "Arial", monospace, sans-serif;
              margin-bottom: 1rem;
              border-bottom: 4px solid #e4a709;
            }
          }
  
          &.center {
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
  
            &:last-of-type {
              padding-bottom: 1rem;
            }
          }
  
          &.left {
            text-align: left;
            //padding-right: 0.8rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
  
            &:last-of-type {
              padding-bottom: 1rem;
            }
          }
  
          &.center1 {
            align-self: center;
            text-align: center;
  
            &:last-of-type {
              padding-top: 4rem;
              margin-bottom: 1rem;
            }
          }
        }
  
        span.center1 {
          text-align: center;
          font-size: 1.6rem;
          font-family: "lexend";
          margin-bottom: 1rem;
          color: #92682B;
        }
  
        img {
          margin-bottom: 2rem;
  
          &.img {
            width: 85%;
          }
  
          &.imgy {
            width: 50%;
            margin-bottom: 2rem;
  
            &.sub2 {
              margin: 0 1rem;
            }
          }
  
          &.img1 {
            width: 30%;
  
            &.sub2 {
              margin: 0 1rem;
            }
          }
  
          &.img2 {
            width: 100%;
          }
  
          &.img3 {
            width: 75%;
            margin-bottom: 0;
          }
  
          &.img4 {
            width: 100%;
            margin-bottom: 0;
          }
  
          &.imgw {
            width: 127px;
          }
  
          &.imgcm {
            width: 35%;
          }
        }
  
        .block{
          display: block;
        }
  
        .imgContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
  
          .galleryImg{
            width: 2.6rem;
            height: 2.6rem;
            pointer-events: none;
            position: absolute;
            bottom: 0;
            left: 74%;
  
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }
  }
  </style>
  