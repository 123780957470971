import * as turf from "@turf/turf";
import Park from "@/models/Park.js";
import store from "../store/index";

const AVERAGE_METERS_PER_HOUR = 5000;
const FAIR_AREA_MULTIPLIER = 14;

class MapUtilities {
  static getPark() {
    return new Park(store.state.parkData);
  }
  // get aprox time to reach certain distance, we use a constant of 5000 meters per minute
  static getAproxTime(distance) {
    let time = Math.round((distance * 60) / AVERAGE_METERS_PER_HOUR);
    if (time > 0 && time < 60) {
      return `${time} min.`;
    } else if (time > 0 && time > 60) {
      return `Más de 1 hora`;
    } else {
      return "Menos de 1 min.";
    }
  }

  // get distance in kilometers given two points with lat,lng type
  static getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  // given a route and a location, this method obtains the point in the route, nearest to the location
  static nearestPointToLocation(route, loc) {
    let line = turf.lineString(route.geometry.coordinates);
    let pt = turf.point([loc[0], loc[1]]);
    var snapped = turf.nearestPointOnLine(line, pt);
    return snapped;
  }

  // get the distance of a series of coordinates forming a route
  static getRouteDistance(route) {
    let distance = 0.0;
    for (let i = 0; i < route.length; i++) {
      if (!route[i + 1]) {
        break;
      }
      const coordStart = route[i];
      const coordEnd = route[i + 1];
      const segmentDistance = this.getDistanceFromLatLonInKm(
        coordStart[0],
        coordStart[1],
        coordEnd[0],
        coordEnd[1]
      );
      distance += segmentDistance;
    }

    return distance;
  }

  // obtain info aboute each section of a route, the info is the following:
  //    start: start of the section
  //    end: end of the section
  //    sectionDistance: Distance of the section in meters
  static getInfoOfRouteSections(route) {
    var totalDistance = 0.0;
    var sectionsInfo = [];
    for (let index = 0; index < route.geometry.coordinates.length; index++) {
      if (!route.geometry.coordinates[index + 1]) {
        break;
      }
      const start = route.geometry.coordinates[index];
      const end = route.geometry.coordinates[index + 1];
      const distance = MapUtilities.km2m(
        MapUtilities.getRouteDistance([start, end])
      );

      totalDistance += distance;
      sectionsInfo.push({
        start: start,
        end: end,
        sectionDistance: distance,
        distanceFromStart: totalDistance,
      });
    }

    return sectionsInfo;
  }

  //Check if coord is inside the park valid area
  static isCoordInsidePark(coord) {
    let parkData = this.getPark();
    let locationPoint = turf.point(coord);
    let parkPolygon = turf.polygon(parkData.parkArea.geometry.coordinates);
    return turf.inside(locationPoint, parkPolygon);
  }

  static getFairAreaOfFeatureCollection(collection) {
    // create fair area
    var fairArea = turf.envelope(collection);
    return turf.transformScale(fairArea, FAIR_AREA_MULTIPLIER);
  }

  // verify if a point (location) is inside a polygon
  static checkIfLocationNearEnoughToRoute(route, location) {
    let locPoint = turf.point([location[0], location[1]]);
    let routePoints = route.geometry.coordinates.map((coord) => {
      return turf.point(coord);
    });
    let featureCollection = turf.featureCollection(routePoints);
    var scaledEnvelope = this.getFairAreaOfFeatureCollection(featureCollection);
    let fixedPolygon = turf.polygon(scaledEnvelope.geometry.coordinates);
    // is point inside polygon formed by the fairArea
    return turf.booleanPointInPolygon(locPoint, fixedPolygon);
  }

  static findDegreeBetweenTwoPoints(startPoint, endPoint) {
    const latitude = startPoint[0];
    const longitude = startPoint[1];
    var pointDegree = this.calcDegreeToPoint(latitude, longitude, endPoint);

    if (pointDegree < 0) {
      pointDegree = pointDegree + 360;
    }

    return this.findIndicatorAngle(pointDegree);
  }

  static findIndicatorAngle(current) {
    return ((current + 180) % 360) - 180;
  }

  static calcDegreeToPoint(latitude, longitude, endPoint) {
    if (endPoint == null) {
      return;
    }

    const phiK = (endPoint[0] * Math.PI) / 180.0;
    const lambdaK = (endPoint[1] * Math.PI) / 180.0;
    const phi = (latitude * Math.PI) / 180.0;
    const lambda = (longitude * Math.PI) / 180.0;

    const psi =
      (180.0 / Math.PI) *
      Math.atan2(
        Math.sin(lambdaK - lambda),
        Math.cos(phi) * Math.tan(phiK) -
          Math.sin(phi) * Math.cos(lambdaK - lambda)
      );

    return Math.round(psi);
  }

  // convert degrees to radians
  static deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  static rad2deg(rad) {
    return (rad * 180) / Math.PI;
  }
  // convert kilometers to meters
  static km2m(km) {
    return turf.convertDistance(km, "kilometers", "meters");
  }
}

export default MapUtilities;
