<template>
  <div class="downloading">
    <figure class="loading-image bounce">
      <img src="@/assets/03_onbording/on_logo.png" alt="loading image" />
    </figure>
    <p>
      {{ $t("downloading.msg1") }}
    </p>
    <p class="highlighted">{{ $t("downloading.msg2") }}</p>
    <p class="progress">{{ `${cachedProgress}%` }}</p>

    <progress max="100" :value="cachedProgress"></progress>
  </div>
</template>

<script>
export default {
  name: "Downloading",
  mounted() {
    const bc = new BroadcastChannel("swChannel");
    bc.onmessage = (event) => {
      this.cachedFiles.push(event);
      this.count = this.cachedFiles.length;
    };
  },
  data() {
    return {
      totalFiles: 748,
      count: 1,
      cachedFiles: [],
    };
  },
  computed: {
    cachedProgress: function () {
      return Math.round((this.count * 100) / this.totalFiles);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.downloading {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 2rem;
  background-color: white;
  color: var(--app-black);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .loading-image {
    position: relative;
    max-width: 8rem;
    width: 40%;
    margin: 1rem;

    img {
      width: 100%;
      height: auto;
    }

    &.bounce {
      animation-name: bounce;
      animation-duration: 2s;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
  }

  p {
    font-size: 1.3rem;
    text-align: center;
    font-family: "Arial", sans-serif;
    line-height: 2rem;
    margin: 0;

    &.highlighted {
      font-family: "ArialCE", sans-serif;
      font-weight: bold;
      font-size: 1.4rem;
      color: var(--app-green);
    }

    &.progress {
      font-family: "Arial-black", sans-serif;
      font-size: 1.8rem;
      margin-top: 2rem;
    }
  }

  progress {
    -webkit-appearance: none;
    border-radius: 5rem;
    overflow: hidden;
    width: 85%;
    margin: 1rem;

    &::-webkit-progress-bar {
      background-color: lightgray;
    }

    &::-webkit-progress-value {
      box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
      background-size: 50px 50px;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      transition: all 0.2s linear;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
