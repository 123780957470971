<template>
  <div class="info">
    <button class="round-btn" @click="closeModal">
      <img :src="require('@/assets/02_generales/cerrar.png')" alt="close button" />
    </button>

    <div v-if="section" class="wrapper">
      <p class="title">
        {{ $t("s-" + section) }}
      </p>
      <div class="content-wrapper" v-html="sectionDescription"></div>
    </div>

    <transition name="fade" mode="out-in">
      <Gallery :images="plainGallery" :index="selectedImageIndex" v-if="galleryIsShown"></Gallery>
    </transition>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
const $ = require('jquery');
window.$ = $;
import { Analytic } from "../../public/js/analytics/Analytic.js";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Info",
};
var analytic = new Analytic(options);
export default {
  name: "Info",
  props: { section: { type: String, required: true } },
  components: {
    Gallery
  },
  async mounted() {
    await this.$nextTick();
    this.$root.$emit("initSidebarWith", this.section);
    this.$root.$emit("initPlayerWith", this.sectionAudio);
    var total = this.$store.state.numRoutes;
    $(".total").html(total);

    if(this.section == "about"){
      $(".content-wrapper").css("padding-right", "0");
    }else{
      $(".content-wrapper").css("padding-right", "0.6rem");
    }

    if(this.section == "discover"){
      $(".galleryImg").css("left", "86%");
    }else{
      $(".galleryImg").css("left", "74%");
    }

    //document.getElementById("imgA").addEventListener("click", this.openGallery);
    document.querySelectorAll('.imgA').forEach(item => {
      item.addEventListener('click', event => {
        this.selectedImageIndex = event.target.dataset.img;
        this.openGallery();
      })
    })
  },
  deactivated() {
    analytic.registerEvent(`Info: ${this.section}`, "closed");
  },
  computed: {
    sectionDescription() {
      analytic.registerEvent(`Info: ${this.section}`, "open");
      return this.$store.state.parkData[this.section].text;
    },
    sectionAudio() {
      return this.$store.state.parkData[this.section].audio;
    },
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/info/${item.src}`);
      });
    },
  },
  watch: {
    $route: function () {
      this.$root.$emit("initPlayerWith", this.sectionAudio);
    },
    "$store.state.parkData": function () {
      this.$root.$emit("initPlayerWith", this.sectionAudio);
    },
  },
  data() {
    return {
      parkData: null,
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      gallery: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeInfo");
    },

    openGallery() {
      this.gallery = [];
      if (this.section == "introduction") {
        this.gallery.push({ "src": "welcome_01.png" },{ "src": "welcome_02.png" },{ "src": "welcome_03.png" },{ "src": "welcome_04.png" });
      } else if (this.section == "discover") {
        this.gallery.push({ "src": "discover1@2x.png" }, { "src": "discover2@2x.png" });
      }
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
};
</script>

<style lang="scss">
.info {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--app-bg);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 0rem 1.7rem;
    overflow: hidden;
    max-height: 90%;

    .title {
      font-family: "lexend";
      font-size: 1.8rem;
      text-align: left;
      margin: 1rem 0 0 0;
      color: #336FB7;
      align-self: flex-start;

      &.special {
        font-family: "lexend";

        &.centro {
          text-align: center;
          width: 100%;
          line-height: 2rem;
        }
      }
    }

    .content-wrapper {
      position: relative;
      flex: 1;
      margin: 1rem 0;
      width: 100%;
      overflow: auto;
      text-align: initial;
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-right: 1.5rem;
      mask-image: linear-gradient(0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%);
      -webkit-mask-image: linear-gradient(0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%);
      padding: 1rem 0rem;
      align-items: center;
      display: flex;
      flex-flow: column;
      padding-right: 0.6rem;

      .store {
        width: 100%;
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-evenly;
        align-items: center;

        .playstore,
        .appstore {
          width: 45%;

          .img1 {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .infDiscover {
        width: 100%;
      }

      p {
        text-align: center;
        //font-size: 1.25rem;
        line-height: 2rem;
        margin-bottom: 3rem;

        &:last-of-type {
          padding-bottom: 1rem;
        }

        b {
          color: #336FB7;
        }

        a {
          color: #336FB7 !important;
          border-bottom: 2px solid;

          b {
            color: #336FB7;
          }
        }

        .total {
          width: auto !important;
        }

        span {
          display: inline-block;
          width: 45%;
          text-align: right;

          &.center1 {
            text-align: center;
            font-family: "Arial", monospace, sans-serif;
            margin-bottom: 1rem;
            border-bottom: 4px solid #e4a709;
          }
        }

        &.center {
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 2rem;

          &:last-of-type {
            padding-bottom: 1rem;
          }
        }

        &.left {
          text-align: left;
          //padding-right: 0.8rem;
          margin-top: 1rem;
          margin-bottom: 1rem;

          &:last-of-type {
            padding-bottom: 1rem;
          }
        }

        &.center1 {
          align-self: center;
          text-align: center;

          &:last-of-type {
            padding-top: 4rem;
            margin-bottom: 1rem;
          }
        }
      }

      span.center1 {
        text-align: center;
        font-size: 1.6rem;
        font-family: "lexend";
        margin-bottom: 1rem;
        color: #92682B;
      }

      img {
        margin-bottom: 2rem;

        &.img {
          width: 85%;
        }

        &.imgy {
          width: 50%;
          margin-bottom: 2rem;

          &.sub2 {
            margin: 0 1rem;
          }
        }

        &.img1 {
          width: 30%;

          &.sub2 {
            margin: 0 1rem;
          }
        }

        &.img2 {
          width: 100%;
        }

        &.img3 {
          width: 75%;
          margin-bottom: 0;
        }

        &.img4 {
          width: 100%;
          margin-bottom: 0;
        }

        &.imgw {
          width: 127px;
        }

        &.imgcm {
          width: 35%;
        }
      }

      .block{
        display: block;
      }

      .imgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .galleryImg{
          width: 2.6rem;
          height: 2.6rem;
          pointer-events: none;
          position: absolute;
          bottom: 0;
          left: 74%;

          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}
</style>
