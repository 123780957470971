<template>
  <div class="crossPena">
    <button class="close" @click="dismissView">
      <img :src="require('@/assets/02_generales/cerrar.png')" alt="close button" />
    </button>

    <div class="wrapper">
      <p class="text">{{ $t("cross.text") }}</p>
      <figure class="penaLogo">
        <img :src="require('@/assets/on_logo@2x.png')" alt="">
      </figure>
      <div class="button"><a href="https://www.penaquest.com" target="_blank">{{ $t("cross.button") }}</a></div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "CrossPena",
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  methods: {
    dismissView() {
      this.$parent.dismissCross();
      //location.reload();
    },
  },
};
</script>
  
<style lang="scss">
.crossPena {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;

  .close {
    position: relative;
    width: 2.6rem;
    height: 2.6rem;
    top: -1rem;
    right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    z-index: 402;
    bottom: 3rem;
    left: 38%;
    align-self: center;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    //flex: 1;
    flex-flow: column;
    align-items: center;
    //margin: 0rem 1.7rem;
    overflow: hidden;
    width: 80%;
    background-color: white;
    align-self: center;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);

    .text{
      font-family: "montserrat";
      font-size: 1.2rem;
      text-align: center;
      margin: 1rem 1.2rem;
    }

    .penaLogo{
      width: 90%;
      height: 9rem;
      margin-bottom: 1rem;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .button{
      padding: 1rem;
      width: 80%;
      font-size: 1.4rem;
      background: #EDA702;
      color: black;
      font-family: "lexend";
      border-radius: 0.8rem;
      text-align: center;
      box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
      margin-bottom: 1rem;
    }
  }
}
</style>
  