<template>
  <transition name="fade">
    <div v-if="showSplash" name="fade" class="splash">
      <img
        class="bg-video"
        :src="imgSrc">
      <figure class="banner">
        <img :src="require('@/assets/PNGBARRA_2024.png')" alt="">
      </figure>
    </div>
  </transition>
</template>
<script>
export default {
  name: "splash",
  data() {
    return {
      phase: 0,
      loading: true,
      imgSrc: require("@/assets/03_onbording/on_logo.png"),
    };
  },
  mounted() { 
    setTimeout(() => {
      this.$store.commit("setShowSplash", false);
    }, 2500);
  },
  computed: {
    showSplash() {
      return this.$store.state.showSplash;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter {
  opacity: 0;
}
.splash {
  position: absolute;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  // background-color: #537b33;

  .banner{
    position: absolute;
    bottom: 5%;
    width: 98%;
    margin: 0 auto;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    
  }

  .logo_s {
    width: 130px;
    height: 242px;
    z-index: 1000;
  }

  .logo_u {
    width: 150px;
    height: 150px;
    z-index: 1000;
  }
}

// video version
.splash {
  background-color: white;

  .bg-video{
    width: 50%;
    animation: shine 2.5s;
  }

  video {
    width: 100%;
  }
}

@keyframes shine{
  0%{
    opacity: 0.5;
    transform: scale(1);
  }
  50%{
    opacity: 0.7;
    transform: scale(1.2);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
</style>
