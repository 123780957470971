<template>
  <div class="next-route">
    <div class="wrapper">
      <div class="next-challenge-info" v-if="this.nextRouteInfo">
        <span class="next-challenge">{{ $t("next-challenge") }}</span>
        <figure class="prize">
          <img :src="require('@/assets/06_camafeos/' + this.nextRouteInfo.prize + '')" alt="">
        </figure>
        <span class="info-name" v-html="this.nextRouteInfo.name"></span>
        <br>

        <p class="info-desc" v-html="routeInformation.info.desc"></p>

        <div class="wrapInfo" v-if="routeInformation.distance !='-'">
          <span class="info-length" v-if="routeInformation"><img :src="require('@/assets/distancia_icon.png')" alt="">{{
              $t("aprox-distance", { distance: routeInformation.distance })
          }}</span>
          <span class="info-duration" v-if="routeInformation"><img :src="require('@/assets/tiempo_icon.png')" alt="">{{
              $t("aprox-time", {
                time: routeInformation.time,
              })
          }}</span>
        </div>

        <!--<div v-if="gallery" class="gallery">
          <div
            v-for="(chunk, index) in getGalleryLayoutContainers"
            :key="index"
            class="layout-wrapper"
            :class="{ special: gallery.length <= 2 }"
          >
            <div v-for="(item, i) in chunk" :key="i" class="item">
              <img
                :src="getImage(item)"
                :data-id="i"
                class="image"
                @click="openGallery"
              />
            </div>
          </div>
        </div>-->
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <Gallery :images="plainGallery" :index="selectedImageIndex" v-if="galleryIsShown"></Gallery>
    </transition>

    <div class="buttons-container">
      <div class="btn" @click="advanceToMap">{{ $t("route") }}</div>
      <div class="btn" @click="advanceToQR">{{ $t("qr") }}</div>
    </div>
  </div>
</template>

<script>
import Park from "@/models/Park.js";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import Gallery from "@/components/Gallery.vue";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "NextRoute",
});

export default {
  name: "NextRoute",
  components: { Gallery },
  watch: {
    "$store.state.locale": function () {
      let routeId = this.$store.state.routeId;
      this.park = new Park(this.$store.state.parkData);

      this.route = this.park.getRouteWithId(routeId);
      this.$store.commit("currentRoute", this.route);
      this.updateNextRouteData();
    },
  },
  mounted() {
    this.$store.state.mapRoute = false;
    let routeId = this.$store.state.routeId;
    this.park = new Park(this.$store.state.parkData);

    if (routeId == "entrada") {
      analytic.registerEvent("Next Route", "entrada");
    } else {
      analytic.registerEvent("Next Route", routeId);
    }
    this.updateNextRouteData();
  },
  data() {
    return {
      park: null,
      ready: false,
      nextRoute: null,
      nextRouteInfo: null,
      currentIndex: null,
      gallery: [],
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      routeInformation: null,
    };
  },
  methods: {
    advanceToMap() {
      this.$router.push({ name: "Map", query: { type: "route" } });
    },
    advanceToQR() {
      this.$router.push("Scanner");
    },
    getImage(img) {
      return require(`@/assets/images/${img}`);
    },
    closeLightbox() {
      setTimeout(() => {
        this.currentIndex = null;
      }, 250);
    },
    updateNextRouteData() {
      let id = this.$store.state.nextRoute.properties.endId;
      this.nextRouteInfo = this.park.getNextRouteInfo(id);

      //get actual info for the next route
      let currentRoute = this.$store.state.currentRoute.id;
      let nextRoute = this.nextRouteInfo.id;
      this.routeInformation =
        this.park.routeInformation[currentRoute][nextRoute];

      this.gallery = this.routeInformation.info.images;
    },
    openGallery(e) {
      this.selectedImage = e.target.src;
      this.selectedImageIndex = e.target.getAttribute("data-id");
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
  computed: {
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/images/${item}`);
      });
    },
    getGalleryLayoutContainers() {
      let galleryCopy = this.routeInformation.info.images;
      var containers = [];
      for (var i = 0; i < galleryCopy.length; i += 3) {
        containers.push(galleryCopy.slice(i, i + 3));
      }

      return containers;
    },
  },
};
</script>

<style lang="scss" scoped>
.next-route {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 7.5rem 2.2rem 0rem 2.2rem;
    overflow: auto;

    .next-challenge-info {
      position: relative;
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 1rem 0.7rem;
      overflow: auto;
      mask-image: linear-gradient(0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%);
      -webkit-mask-image: linear-gradient(0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 2%,
          rgba(0, 0, 0, 1) 98%,
          rgba(0, 0, 0, 0) 100%);

      .wrapInfo {
        display: flex;
        flex-flow: row;
        width: 14rem;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        margin-top: 1rem;

        span {
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          font-family: "montserrat";
          color: rgba(0, 0, 0, 0.7);
          margin-bottom: 0.6rem;
          margin-top: 0;

          img {
            width: 2rem;
            height: 1.6rem;
            object-fit: contain;
            object-position: center;
            margin-right: 0.3rem;
          }
        }
      }

      span {
        text-align: initial;
      }

      .next-challenge {
        font-size: 1.6rem;
        font-family: "lexend";
        color: #336FB7;
        margin: 1rem 0rem;
        text-align: center;
      }

      .info-name {
        font-family: "lexend";
        font-size: 1.6rem;
        color: #786721;
        text-align: center;
      }

      .prize {
        width: 100%;
        height: 10rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      /*.info-length,
      .info-duration,
      .info-slope {
        font-family: "Arial", monospace, sans-serif;
        font-weight: 400;
        font-size: 1.3rem;

        &.info-length {
          margin-top: 2rem;
        }

        &.info-slope {
          margin-bottom: 2rem;
        }
      }*/

      .info-desc {
        text-align: left;
        line-height: 2rem;
      }

      .gallery {
        margin: 0 auto;
        width: 100%;

        /* Gallery */
        .layout-wrapper {
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          gap: 0.5rem;

          &:not(:first-of-type) {
            margin-top: 0.5rem;
          }

          &.special {
            // background-color: green;
            display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-template-columns: unset;
            grid-template-rows: unset;
            gap: unset;

            .item {
              position: relative;
              width: 100%;
              height: auto;
              grid-column: unset;
              grid-row: unset;
              margin: 0rem 0.5rem;
            }
          }

          .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 10rem;
            overflow: hidden;

            .image {
              position: absolute;
              width: 100%;
              height: 100%;
              margin: 0;
              object-fit: cover;
              object-position: center;
            }

            &:nth-child(3n + 1) {
              grid-column: 1;
              grid-row: 1 / span 2;
            }

            &:nth-child(3n + 2) {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
            }

            &:nth-child(3n + 3) {
              grid-column: 2 / span 1;
              grid-row: 2 / span 1;
            }
          }
        }
      }
    }
  }

  .buttons-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 2.5rem;

    .btn {
      position: relative;
      width: 47%;
      background: var(--app-buttonG);
      color: var(--app-black);
      font-family: "lexend";
      border-radius: 0.8rem;
      text-align: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.2s ease;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      padding: 0.8rem;
      color: #F8F7F3;

      img {
        width: 1.2rem;
        height: 1.2rem;
        object-fit: contain;
        object-position: center;
        margin-left: 0.3rem;
      }
    }
  }
}
</style>
