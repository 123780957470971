<template>
  <div class="Discover">
    <button class="round-btn" @click="closeModal">
      <img
        :src="require('@/assets/02_generales/cerrar.png')"
        alt="close button"
      />
    </button>

    <div v-if="section" class="wrapper">
      <p class="title">
        {{ $t("s-discover") }}
      </p>
      <div class="content-wrapper" v-html="getDescription"></div>
    </div>
    <transition name="fade" mode="out-in">
      <Gallery :images="plainGallery" :index="selectedImageIndex" v-if="galleryIsShown"></Gallery>
    </transition>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
import { Analytic } from "../../public/js/analytics/Analytic.js";
const $ = require('jquery');
window.$ = $;
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Discover",
};
var analytic = new Analytic(options);
export default {
  name: "Discover",
  async mounted() {
    await this.$nextTick();
    analytic.registerEvent(`Discover`, "open");
    this.section = this.$store.state.parkData["discover"];
    this.$root.$emit("initSidebarWith", "discover");
  },
  updated() {
    document.querySelectorAll('.imgA').forEach(item => {
      item.addEventListener('click', event => {
        this.selectedImageIndex = event.target.dataset.img;
        this.openGallery();
      })
    })
  },
  components: {
    Gallery
  },
  deactivated() {
    analytic.registerEvent(`Discover`, "closed");
  },
  data() {
    return {
      section: null,
      galleryIsShown: false,
      selectedImage: null,
      selectedImageIndex: null,
      gallery: [],
    };
  },
  computed: {
    getDescription() {
      return this.$store.state.parkData["discover"].text;
    },
    plainGallery() {
      if (this.gallery == null || this.gallery == undefined) {
        return [];
      }

      return this.gallery.map((item) => {
        return require(`@/assets/info/${item.src}`);
      });
    },
  },
  methods: {
    closeModal() {
      this.$router.go(-1);
    },
    openGallery() {
      this.gallery = [];
      this.gallery.push({ "src": "discover1@2x.png" }, { "src": "discover2@2x.png" });
      this.galleryIsShown = true;
    },
    closeGallery() {
      this.galleryIsShown = false;
    },
  },
};
</script>

<style lang="scss">
.Discover {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--app-bg);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    margin: 0rem 1.7rem;
    overflow: hidden;
    max-height: 90%;
    margin-top: 7.5rem;

    .title {
      font-size: 1.6rem;
      color: #336FB7;
      font-family: "lexend";
      font-size: 1.6rem;
      text-align: left;
      margin: 1rem 0 0 0;
      align-self: flex-start;
      &.special {
        font-family: "lexend";
        &.centro {
          text-align: center;
          width: 100%;
          line-height: 2rem;
        }
      }
    }

    .content-wrapper {
      position: relative;
      flex: 1;
      margin: 1rem 0;
      width: 100%;
      overflow: auto;
      text-align: initial;
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-right: 1.5rem;
      mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 2%,
        rgba(0, 0, 0, 1) 98%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 1rem 0.6rem;
      align-items: center;
      display: flex;
      flex-flow: column;
      font-family: "montserrat";

      .block{
        display: block;
      }

      .imgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .galleryImg{
          width: 2.6rem;
          height: 2.6rem;
          pointer-events: none;
          position: absolute;
          bottom: 0;
          left: 86%;

          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .store{
        width: 100%;
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-evenly;
        align-items: center;

        .playstore, .appstore{
          width: 45%;

          .img1{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      p {
        text-align: center;
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 3rem;
        width: 100%;
        a{
          color: #336FB7;
          border-bottom: 2px solid;
        }
        &:last-of-type {
          padding-bottom: 1rem;
        }
        span {
          display: inline-block;
          width: 45%;
          text-align: right;
          &.center1 {
            text-align: center;
            font-family: "Arial", monospace, sans-serif;
            margin-bottom: 1rem;
            border-bottom: 4px solid #e4a709;
          }
        }
        &.center {
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 2rem;
          &:last-of-type {
            padding-bottom: 1rem;
          }
        }
        &.left {
          text-align: left;
          padding-right: 0.8rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          &:last-of-type {
            padding-bottom: 1rem;
          }
        }
        &.center1 {
          align-self: center;
          text-align: center;
          &:last-of-type {
            padding-top: 4rem;
          }
        }
      }
      span.center1 {
        text-align: center;
        font-size: 1.5rem;
        font-family: "Arial", monospace, sans-serif;
        margin-bottom: 1rem;
        border-bottom: 4px solid #e4a709;
      }
      img {
        margin-bottom: 2rem;
        &.img {
          width: 100%;
        }
        &.imgy {
          width: 50%;
          margin-bottom: 2rem;
          &.sub2 {
            margin: 0 1rem;
          }
        }
        &.img2 {
          width: 100%;
        }
        &.img3 {
          width: 100%;
        }
        &.img4 {
          width: 100%;
          margin-bottom: 0;
        }
        &.imgw {
          width: 127px;
        }
        &.imgcm {
          width: 167px;
        }
      }
    }
  }
}
</style>
