import Park from "@/models/Park.js";
import store from "../store/index";

class NextRoute {
  constructor(currentRoute) {
    this.currentRoute = currentRoute;
    this.nextRoute = null;
    this.park = new Park(store.state.parkData);
  }

  getRouteToSpot(spot) {
    var toNextPoint;
    let routeToEndPoint = this.park.startChecking(this.currentRoute, spot);
    var routes = [];
    routeToEndPoint.forEach((routeSpots) => {
      let route = this.park.buildRoute(routeSpots);
      let distance = this.park.getRouteDistance(route.geometry.coordinates);

      routes.push({
        route: route,
        distance: distance,
      });
    });

    if (routes.length > 1) {
      let sorted = this.park.sortRouteByDistances(routes, "distance");
      toNextPoint = sorted[0];
    } else {
      toNextPoint = routes[0];
    }

    return toNextPoint;
  }

  getNextRouteData() {
    var distances = this.park.getDistancesToAllSpotsLeft(store.state.routeId);
    let currentIndex = distances.findIndex(function (d) {
      return d.distance == 0;
    });

    let nextPoint = this.findNextPoint(currentIndex, distances);
    if (nextPoint) {
      let routeToEndPoint = this.park.startChecking(
        nextPoint.from,
        nextPoint.to
      );

      var routes = [];
      routeToEndPoint.forEach((routeSpots) => {
        let route = this.park.buildRoute(routeSpots);
        let distance = this.park.getRouteDistance(route.geometry.coordinates);

        routes.push({
          route: route,
          distance: distance,
        });
      });

      if (routes.length > 1) {
        let sorted = this.park.sortRouteByDistances(routes, "distance");
        this.nextRoute = sorted[0];
      } else {
        this.nextRoute = routes[0];
      }
      store.commit("nextRoute", this.nextRoute.route);
    } else {
      store.commit("nextRoute", null);
    }
  }

  findNextPoint(index, distances) {
    let probNextSpot = null;
    if (index + 1 > distances.length - 1) {
      probNextSpot = this.getNexSpotByDistance(distances);
    } else {
      // keep with normal order unless all next sports are done
      probNextSpot = this.getNexSpotByOrder(index, distances);
      if (probNextSpot == null) {
        // no order available, try with distance then
        probNextSpot = this.getNexSpotByDistance(distances);
      }
    }

    return probNextSpot;
  }

  getNexSpotByDistance(distances) {
    let distancesByDistance = this.park.sortArrayDistancesBy(
      distances,
      "distance"
    );

    let probNextSpot = distancesByDistance.find((d) => {
      return (
        d.distance > 0 && !store.getters.routeWasFinished(d.to.properties.id)
      );
    });
    return probNextSpot;
  }

  getNexSpotByOrder(index, distances) {
    var probNextSpot;
    var areTherePossiblePoints;
    var iterationIndex = index;
    let routeInfo = this.park.getSpotInfo(store.state.routeId);
    let distancesByOrder = this.park.sortArrayDistancesBy(distances, "order");

    do {
      iterationIndex += 1;
      areTherePossiblePoints =
        distances.filter(
          (d) =>
            d.order >= iterationIndex &&
            d.to.properties.id != routeInfo.properties.id &&
            !store.getters.routeWasFinished(d.to.properties.id)
        ).length > 0;

      probNextSpot = distancesByOrder.find(
        (d) =>
          d.order >= iterationIndex &&
          d.to.properties.id != routeInfo.properties.id &&
          !store.getters.routeWasFinished(d.to.properties.id)
      );
    } while (!probNextSpot && areTherePossiblePoints);
    return probNextSpot || null;
  }
}

export default NextRoute;
