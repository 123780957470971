<template>
  <div class="home">
    <vue-scroll-snap :fullscreen="true" :horizontal="true">
      <div v-if="parkData" class="wrapper item">
        <div class="bigspace"></div>
        <p class="title titletop">{{ $t("rating_visit") }}</p>
        <figure class="logo">
          <img :src="logo" alt="" />
        </figure>
        <p class="title" v-html="$t('rating_question')"></p>
        <div class="rating">
          <ul class="list" :class="{ disabled: disabled }">
            <li
              :key="star"
              v-for="star in maxstars"
              @mouseover="hoverStar(star)"
              @mouseleave="mouseLeftStar"
              :class="[{ active: star <= stars }]"
              @click="rate(star)"
              class="star"
            >
              <span></span>
            </li>
          </ul>
          <span class="rating" v-if="hasdescription && star_desc">{{
            $t(star_desc.class)
          }}</span>
        </div>
        <div class="bigspace"></div>
      </div>
      <div v-if="parkData" class="wrapper item">
        <div class="bigspace"></div>
        <div class="form">
          <label class="small question">{{ $t("q1") }}</label>
          <div class="radiocontainer">
            <input
              type="radio"
              id="q1si"
              name="q1"
              value="si"
              v-model="q1"
              checked
            /><label class="small" for="q1si">{{ $t("q_yes") }}</label>
            <input
              type="radio"
              id="q1no"
              name="q1"
              value="no"
              v-model="q1"
            /><label class="small" for="q1no">{{ $t("q_no") }}</label>
          </div>
          <label class="small question">{{ $t("q3") }}</label>
          <!--Q3 ES Q2 EN LA DB -->
          <div class="radiocontainer">
            <input
              type="radio"
              id="q3si"
              name="q3"
              value="si"
              v-model="q3"
              checked
            /><label class="small" for="q3si">{{ $t("q_yes") }}</label>
            <input
              type="radio"
              id="q4no"
              name="q3"
              value="no"
              v-model="q3"
            /><label class="small" for="q3no">{{ $t("q_no") }}</label>
          </div>
          <label class="small">{{ $t("name") }}</label>
          <textarea
            v-model="user_suggestion"
            class="ancho"
            name="nombre"
            type="text"
            maxlength="2000"
            rows="10"
          />
        </div>
        <big-button
          :onClick="start"
          :class="[{ hidden: currentBullet == 0 }]"
          >{{ $t("rating_submit") }}</big-button
        >
      </div>
    </vue-scroll-snap>
    <transition name="fade">
      <div v-if="currentBullet == 0" class="bullets">
        <div class="dot" :class="[{ active: currentBullet == 0 }]"></div>
        <div class="dot" :class="[{ active: currentBullet == 1 }]"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import BigButton from "@/components/BigButton.vue";
import VueScrollSnap from "vue-scroll-snap";
import { Analytic } from "../../public/js/analytics/Analytic.js";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Scanner",
};
var analytic = new Analytic(options);

export default {
  name: "Rating",
  components: {
    BigButton,
    VueScrollSnap,
  },
  watch: {
    "$store.state.parkData": function () {
      this.parkData = this.$store.state.parkData;
      this.$i18n.locale = this.$store.state.locale;
    },
  },
  mounted() {
    analytic.registerEvent("Rating", "open");
    this.star_desc = this.ratingdescription[this.stars - 1];
    var container = this.$el.querySelector(".scroll-snap-container");
    container.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  },
  data() {
    return {
      parkData: this.$store.state.parkData,
      star: 5, // default star
      stars: 5,
      ratingdescription: [
        {
          text: "Poor",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-belowAverage",
        },
        {
          text: "Average",
          class: "star-average",
        },
        {
          text: "Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      hasresults: true,
      hasdescription: true,
      starsize: "lg", //[xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
      maxstars: 5,
      disabled: false,
      star_desc: {},
      q1: "",
      q3: "",
      user_suggestion: "",
      currentBullet: 0,
    };
  },
  computed: {
    logo() {
      return require("@/assets/03_onbording/on_logo.png");
    },
  },
  methods: {
    start() {
      analytic.saveRating(
        this.user_suggestion,
        this.stars,
        this.q1,
        this.q3,
        () => {}
      );
      this.submittext = this.$t("rating_submit2");
      setTimeout(() => {
        analytic.registerEvent("Rating", "closed");
        this.$store.commit("setShowRating", false);
        this.$router.push("Complete");
      }, 1500);
    },
    goForm() {
      var container = this.$el.querySelector(".scroll-snap-container");
      container.scrollLeft = container.scrollWidth;
    },
    rate(star) {
      if (this.disabled) {
        return;
      }
      if (star <= this.maxstars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star;
        this.star_desc = this.ratingdescription[star - 1];
      }
    },
    hoverStar(star) {
      if (this.disabled) {
        return;
      }
      if (star <= this.maxstars && star >= 0) {
        this.star_desc = this.ratingdescription[star - 1];
      }
    },
    mouseLeftStar() {
      if (this.disabled) {
        return;
      }
      if (this.stars) {
        this.star_desc = this.ratingdescription[this.stars - 1];
        return this.star_desc;
      } else {
        this.star_desc = "";
      }
    },
    getRatingDesc(star) {
      if (star) {
        this.star_desc = this.ratingdescription[star - 1];
      }
      return this.star_desc;
    },
    handleScroll: function (e) {
      e.target;
      var containerScroll = this.$el.querySelector(
        ".scroll-snap-container"
      ).scrollLeft;
      var item = this.$el.querySelector(".item:nth-of-type(1)");
      this.currentBullet = Math.floor(containerScroll / item.offsetWidth);
    },
  },
};
</script>

<style lang="scss" >
.home {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: var(--app-bg);
  overflow: hidden;
  .scroll-snap-container {
    scrollbar-width: none;
    align-items: center;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    padding: 0rem 1.7rem;
    justify-content: space-between;
    margin: 0;
    overflow: auto;
    max-height: 90%;

    .logo {
      position: relative;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: relative;
        height: auto;
        width: 100%;
      }
    }

    .title {
      font-family: "lexend";
      font-size: 1.6rem;
      margin: 1rem;
      color: #336FB7;
      text-align: center;
    }
    .titletop{
      color: #92682B;
    }
    .title > span {
      font-family: "Righteous", monospace, sans-serif;
    }
    .title2 {
      font-family: "Arial-Black", monospace, sans-serif;
      font-size: 1.6rem;
      margin: 1rem;
      font-weight: 700;
    }

    .text-wrapper {
      flex: 1;
      margin: 0.5rem 0;
      padding: 0 0.5rem;
      padding-right: 0.5rem;
      overflow: auto;
    }

    p {
      text-align: initial;
    }

    .form {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      min-width: 100%;
      label {
        //font-family: "lexend";
        font-size: 1.6rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        &.small {
          font-size: 1rem;
        }
        &.question {
          text-align: left;
        }
      }
      div.radiocontainer {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        input {
          //flex: 1;
        }
        label {
          flex: 0.2;
          text-align: left;
        }
      }
      textarea {
        background-color: var(--app-bg);
        resize: none;
      }
      textarea.ancho {
        font-size: 20px;
        margin: 0 0 2rem 0;
        width: 100%;
      }
    }
  }

  .bigspace {
    height: 73px;
    margin: 0.5rem 0;
  }
  .big-button {
    &.hidden {
      opacity: 0;
      transition: opacity 0.5s ease;
    }
  }
}
$active-color: #f3d23e;
$white-color: #fff;
$poor-color: rgb(186, 186, 186);
$below-average-color: rgb(245, 195, 87);
$average-color: rgb(255, 172, 90);
$good-color: rgb(193, 215, 89);
$excellent-color: rgb(72, 150, 77);
$no-star-desc-color: rgb(00, 00, 00);
.rating {
  font-family: "montserrat";
  font-size: 1rem;
}
ul.list li,
span {
  display: inline-block;
  margin: 2px;
}
.list {
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: $active-color;
    }
  }
  span {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    padding: 5px;
    color: $white-color;
    border-radius: 2px;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    transition: 0.2s;
    line-height: 25px;
  }
}

.bullets {
  position: absolute;
  bottom: 5%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 100%;

  .dot {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
    background-image: url("./../assets/03_onbording/dots/on_selec2.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 0.5rem;
    &.active {
      background-image: url("./../assets/03_onbording/dots/on_selec1.png");
    }
  }
}
.list.disabled {
  &:hover {
    .star {
      color: black;
      cursor: default;
    }
    .star.active {
      color: $active-color;
    }
  }
}

.star {
  cursor: pointer;
  background-image: url("~@/assets/07_estrella/estrella_2.png");
  background-repeat: no-repeat;
  background-size: contain;
  &:hover {
    & ~ .star {
      &:not(.active) {
        color: inherit;
      }
    }
  }
  &.active {
    color: $active-color;
    background-image: url("~@/assets/07_estrella/estrella_1.png");
  }
}
.star-poor {
  background: $poor-color;
}
.star-belowAverage {
  background: $below-average-color;
}
.star-average {
  background: $active-color;
}
.star-good {
  background: $good-color;
}
.star-excellent {
  background: $excellent-color;
}
.nostar_desc {
  background: $no-star-desc-color;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
