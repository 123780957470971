<template>
    <div class="progress-bar" :class="{ disabled: this.$store.state.disabledLanguages }">
      <div class="progress-cont">
          <figure class="bar">
              <img :src="setImgBar" alt="">
          </figure>
          <figure class="star-bar" @click="goRewards">
              <img :src="setStar" alt="">
          </figure>
      </div>
    </div>
  </template>
  
  <script>
  const $ = require('jquery');
  window.$ = $;
  
  export default {
    name: "progress-bar",
    mounted() {
    },
    computed: {
      setImgBar(){
          let totalRewards = this.$store.state.finishedChallenges.length;
          if(this.typeRoute != 1){
              if(totalRewards == null){
                  return require(`@/assets/progress/barra_6_00.png`);
              }else{
                  return require(`@/assets/progress/barra_6_0${totalRewards}.png`);
              }
          }else{
              if(totalRewards == null){
                  return require(`@/assets/progress/barra_8_00.png`);
              }else{
                  return require(`@/assets/progress/barra_8_0${totalRewards}.png`);
              }
          }
      },
      setStar(){
          let isWinnerCode = this.$store.state.winnerCode;
          if(isWinnerCode != null){
              return require(`@/assets/progress/star_on.png`);
          }else{
              return require(`@/assets/progress/star_off.png`);
          }
      }
    },
    data() {
      return {
        typeRoute: this.$store.state.typeRoute,
      };
    },
    methods: {
      goRewards(){
          this.$router.push("Rewards");
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .progress-bar {
    display: flex;
    flex-flow: row;
    position: absolute;
    top: 5rem;
    z-index: 1;
    width: 100%;
    height: 2rem;
    justify-content: center;
  
    &.disabled {
      pointer-events: none;
    }
  
    .progress-cont{
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      width: 90%;
  
      .bar{
          width: 87.5%;
      }
  
      .star-bar{
          width: 2.5rem;
          height: 2.5rem;
      }
  
      img{
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
      }
    }
  }
  </style>
  